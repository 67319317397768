export const static_settings = {
    app_name: 'OVO PoS',
    app_icon: '/images/icon.png',
    terms_url: 'https://ovopos.com/terms',
    privacy_url: 'https://ovopos.com/privacy',
    api: {
        //base_url: 'http://localhost/ovoposserver/public',
        base_url: 'https://api.ovopos.com',
        endpoints: {
            account:{
                accountsDatatable: '/api/account/all-datatable',
                accounts: '/api/account/all',
                account: '/api/account/view',
                accountSave: '/api/account/save',
                accountSaveFile: '/api/account/save-file',
                accountDelete: '/api/account/delete',
                searchAggregatedAccounts: '/api/account/search-aggregated-accounts',
            },
            authentication: {
                signupProcess: '/api/signup',
                loginProcess: '/api/login',
                logout: '/api/logout',
                forgotOTP: '/api/forgotOTP',
                forgotReset: '/api/forgotReset',
            },
            billing:{
                billingInvoicesDatatable: '/api/billing-invoice/all-datatable',
                billingInvoices: '/api/billing-invoice/all',
                billingInvoice: '/api/billing-invoice/view',
                billingInvoiceSave: '/api/billing-invoice/save',
                billingInvoiceDelete: '/api/billing-invoice/delete',
                billingSubscriptionsDatatable: '/api/billing-subscription/all-datatable',
                billingSubscriptions: '/api/billing-subscription/all',
                billingInvoiceCalculation: '/api/billing-invoice-calculation',
                billingWalletTransactionsDatatable: '/api/billing-wallet-transaction/all-datatable',
                billingWalletBalance: '/api/billing-wallet-balance',
            },
            ca:{
                caOnes: '/api/ca-ones',
                caTwos: '/api/ca-twos',
                caThrees: '/api/ca-threes',
                caWithAccounts: '/api/ca-with-accounts',
            },
            company: {
                companyLoad: '/api/load-company',
                companySave: '/api/company-save',
                companySaveFile: '/api/company-save-file',
            },
            countries: '/api/countries',
            csrf: '/sanctum/csrf-cookie',
            customer:{
                customersDatatable: '/api/customer/all-datatable',
                customers: '/api/customer/all',
                customer: '/api/customer/view',
                customerSave: '/api/customer/save',
                customerSaveFile: '/api/customer/save-file',
                customerDelete: '/api/customer/delete',
            },
            customerCategory:{
                customerCategoriesDatatable: '/api/customer-category/all-datatable',
                customerCategories: '/api/customer-category/all',
                customerCategory: '/api/customer-category/view',
                customerCategorySave: '/api/customer-category/save',
                customerCategoryDelete: '/api/customer-category/delete',
            },
            log:{
                logsDatatable: '/api/log/all-datatable',
                logs: '/api/log/all',
                log: '/api/log/view',
                logSave: '/api/log/save',
                logDelete: '/api/log/delete',
            },
            dashboard:{
                statistics: '/api/dashboard/statistics',
            },
            openingBalance:{
                openingBalance: '/api/opening-balance/view',
                openingBalanceSave: '/api/opening-balance/save',
                openingBalanceDelete: '/api/opening-balance/delete'
            },
            options: '/api/option/all',
            package:{
                packageKeyPermission: '/api/package/package-key-permission',
                packages: '/api/package/all',
            },
            payment:{
                paymentsDatatable: '/api/payment/all-datatable',
                payments: '/api/payment/all',
                payment: '/api/payment/view',
                paymentSave: '/api/payment/save',
                paymentSaveFile: '/api/payment/save-file',
                paymentDelete: '/api/payment/delete',
            },
            paymentMethod:{
                paymentMethodsDatatable: '/api/payment-method/all-datatable',
                paymentMethods: '/api/payment-method/all',
                paymentMethod: '/api/payment-method/view',
                paymentMethodSave: '/api/payment-method/save',
                paymentMethodDelete: '/api/payment-method/delete',
            },
            product:{
                productsDatatable: '/api/product/all-datatable',
                products: '/api/product/all',
                product: '/api/product/view',
                productSave: '/api/product/save',
                productSaveFile: '/api/product/save-file',
                productDelete: '/api/product/delete',
                stockTransfersDatatable: '/api/stock-transfer/all-datatable',
                stockTransfers: '/api/stock-transfer/all',
                stockTransfer: '/api/stock-transfer/view',
                stockTransferSave: '/api/stock-transfer/save',
                stockTransferDelete: '/api/stock-transfer/delete',
            },
            productBrand:{
                productBrandsDatatable: '/api/product-brand/all-datatable',
                productBrands: '/api/product-brand/all',
                productBrand: '/api/product-brand/view',
                productBrandSave: '/api/product-brand/save',
                productBrandSaveFile: '/api/product-brand/save-file',
                productBrandDelete: '/api/product-brand/delete',
            },
            productCat:{
                productCatsDatatable: '/api/product-cat/all-datatable',
                productCats: '/api/product-cat/all',
                productCat: '/api/product-cat/view',
                productCatSave: '/api/product-cat/save',
                productCatSaveFile: '/api/product-cat/save-file',
                productCatDelete: '/api/product-cat/delete',
            },
            productGroup:{
                productGroupsDatatable: '/api/product-group/all-datatable',
                productGroups: '/api/product-group/all',
                productGroup: '/api/product-group/view',
                productGroupSave: '/api/product-group/save',
                productGroupDelete: '/api/product-group/delete',
            },
            productUnit:{
                productUnitsDatatable: '/api/product-unit/all-datatable',
                productUnits: '/api/product-unit/all',
                productUnit: '/api/product-unit/view',
                productUnitSave: '/api/product-unit/save',
                productUnitDelete: '/api/product-unit/delete',
            },
            purchase:{
                purchasesDatatable: '/api/purchase/all-datatable',
                purchase: '/api/purchase/view',
                purchaseSave: '/api/purchase/save',
                purchaseSaveFile: '/api/purchase/save-file',
                purchaseDelete: '/api/purchase/delete',
            },
            report:{
                invoicesList: '/api/report/invoices-list',
                entitiesInvoicesSummary: '/api/report/entities-invoices-summary',
                entityLedger: '/api/report/entity-ledger',
                productLedger: '/api/report/product-ledger',
                productsInventory: '/api/report/products-inventory',
                inflowOutflow: '/api/report/inflow-outflow',
            },
            sale:{
                salesDatatable: '/api/sale/all-datatable',
                sales: '/api/sale/all',
                sale: '/api/sale/view',
                saleSave: '/api/sale/save',
                saleSaveFile: '/api/sale/save-file',
                saleDelete: '/api/sale/delete',
            },
            shipping:{
                shippings: '/api/shipping/all',
                shippingSave: '/api/shipping/save',
                shippingDelete: '/api/shipping/delete',
            },
            shippingStatus:{
                shippingStatusesDatatable: '/api/shippingStatus/all-datatable',
                shippingStatuses: '/api/shippingStatus/all',
                shippingStatus: '/api/shippingStatus/view',
                shippingStatusSave: '/api/shippingStatus/save',
                shippingStatusSaveFile: '/api/shippingStatus/save-file',
                shippingStatusDelete: '/api/shippingStatus/delete',
            },
            store:{
                storesDatatable: '/api/store/all-datatable',
                stores: '/api/store/all',
                store: '/api/store/view',
                storeSave: '/api/store/save',
                storeSaveFile: '/api/store/save-file',
                storeDelete: '/api/store/delete',
            },
            supplier:{
                suppliersDatatable: '/api/supplier/all-datatable',
                suppliers: '/api/supplier/all',
                supplier: '/api/supplier/view',
                supplierSave: '/api/supplier/save',
                supplierSaveFile: '/api/supplier/save-file',
                supplierDelete: '/api/supplier/delete',
            },
            supplierCategory:{
                supplierCategoriesDatatable: '/api/supplier-category/all-datatable',
                supplierCategories: '/api/supplier-category/all',
                supplierCategory: '/api/supplier-category/view',
                supplierCategorySave: '/api/supplier-category/save',
                supplierCategoryDelete: '/api/supplier-category/delete',
            },
            user:{
                userInit: '/api/user-init',
                usersDatatable: '/api/user/all-datatable',
                users: '/api/user/all',
                user: '/api/user',
                userSave: '/api/user-save',
                userSaveFile: '/api/user-save-file',
                userPermissions: '/api/user-permissions/',
                userPermissionsSave: '/api/user-permissions-save',
                userDelete: '/api/user/delete',
            },
        },
    },
    customDataTable:{  },
    loading: { 'color': '#009688', canCancel: false, loader: 'spinner', width: 70, height: 70, opacity: 0.5 },
    permissions: {
        account: {
            account_list: 'account_list',
            account_view: 'account_view',
            account_create: 'account_create',
            account_edit: 'account_edit',
            account_delete: 'account_delete',
        },
        customer: {
            customer_list: 'customer_list',
            customer_view: 'customer_view',
            customer_create: 'customer_create',
            customer_edit: 'customer_edit',
            customer_delete: 'customer_delete',
        },
        customerCategory: {
            customerCategory_list: 'customerCategory_list',
            customerCategory_view: 'customerCategory_view',
            customerCategory_create: 'customerCategory_create',
            customerCategory_edit: 'customerCategory_edit',
            customerCategory_delete: 'customerCategory_delete',
        },
        payment: {
            payment_list: 'payment_list',
            payment_view: 'payment_view',
            payment_create: 'payment_create',
            payment_edit: 'payment_edit',
            payment_delete: 'payment_delete',
        },
        paymentMethod: {
            paymentMethod_list: 'paymentMethod_list',
            paymentMethod_view: 'paymentMethod_view',
            paymentMethod_create: 'paymentMethod_create',
            paymentMethod_edit: 'paymentMethod_edit',
            paymentMethod_delete: 'paymentMethod_delete',
        },
        product: {
            product_list: 'product_list',
            product_view: 'product_view',
            product_create: 'product_create',
            product_edit: 'product_edit',
            product_delete: 'product_delete',
            stock_transfer_list: 'stock_transfer_list',
            stock_transfer_view: 'stock_transfer_view',
            stock_transfer_create: 'stock_transfer_create',
            stock_transfer_edit: 'stock_transfer_edit',
            stock_transfer_delete: 'stock_transfer_delete',
        },
        productBrand: {
            productBrand_list: 'productBrand_list',
            productBrand_view: 'productBrand_view',
            productBrand_create: 'productBrand_create',
            productBrand_edit: 'productBrand_edit',
            productBrand_delete: 'productBrand_delete',
        },
        productCat: {
            productCat_list: 'productCat_list',
            productCat_view: 'productCat_view',
            productCat_create: 'productCat_create',
            productCat_edit: 'productCat_edit',
            productCat_delete: 'productCat_delete',
        },
        productGroup: {
            productGroup_list: 'productGroup_list',
            productGroup_view: 'productGroup_view',
            productGroup_create: 'productGroup_create',
            productGroup_edit: 'productGroup_edit',
            productGroup_delete: 'productGroup_delete',
        },
        productUnit:{
            productUnit_list: 'productUnit_list',
            productUnit_view: 'productUnit_view',
            productUnit_create: 'productUnit_create',
            productUnit_edit: 'productUnit_edit',
            productUnit_delete: 'productUnit_delete',
            stock_transfer_list: 'stock_transfer_list',
            stock_transfer_view: 'stock_transfer_view',
            stock_transfer_create: 'stock_transfer_create',
            stock_transfer_edit: 'stock_transfer_edit',
            stock_transfer_delete: 'stock_transfer_delete',
        },
        purchase: {
            /*purchase_order_list: 'purchase_order_list',
            purchase_order_view: 'purchase_order_view',
            purchase_order_create: 'purchase_order_create',
            purchase_order_edit: 'purchase_order_edit',
            purchase_order_delete: 'purchase_order_delete',*/
            purchase_invoice_list: 'purchase_invoice_list',
            purchase_invoice_view: 'purchase_invoice_view',
            purchase_invoice_create: 'purchase_invoice_create',
            purchase_invoice_edit: 'purchase_invoice_edit',
            purchase_invoice_delete: 'purchase_invoice_delete',
            purchase_return_list: 'purchase_return_list',
            purchase_return_view: 'purchase_return_view',
            purchase_return_create: 'purchase_return_create',
            purchase_return_edit: 'purchase_return_edit',
            purchase_return_delete: 'purchase_return_delete',
            //purchase_shipping_management: 'purchase_shipping_management',
        },
        report: {
            report_sales_list: 'report_sales_list',
            report_customers_sales_summary: 'report_customers_sales_summary',
            report_customer_ledger: 'report_customer_ledger',
            report_account_ledger: 'report_account_ledger',
            report_inflow_outflow: 'report_inflow_outflow',
            report_product_ledger: 'report_product_ledger',
            report_products_inventory: 'report_products_inventory',
            report_purchases_list: 'report_purchases_list',
            report_suppliers_purchases_summary: 'report_suppliers_purchases_summary',
            report_supplier_ledger: 'report_supplier_ledger',
        },
        sale: {
            sale_order_list: 'sale_order_list',
            sale_order_view: 'sale_order_view',
            sale_order_create: 'sale_order_create',
            sale_order_edit: 'sale_order_edit',
            sale_order_delete: 'sale_order_delete',
            sale_invoice_list: 'sale_invoice_list',
            sale_invoice_view: 'sale_invoice_view',
            sale_invoice_create: 'sale_invoice_create',
            sale_invoice_edit: 'sale_invoice_edit',
            sale_invoice_delete: 'sale_invoice_delete',
            sale_return_list: 'sale_return_list',
            sale_return_view: 'sale_return_view',
            sale_return_create: 'sale_return_create',
            sale_return_edit: 'sale_return_edit',
            sale_return_delete: 'sale_return_delete',
            sale_shipping_management: 'sale_shipping_management',
        },
        supplier: {
            supplier_list: 'supplier_list',
            supplier_view: 'supplier_view',
            supplier_create: 'supplier_create',
            supplier_edit: 'supplier_edit',
            supplier_delete: 'supplier_delete',
        },
        supplierCategory: {
            supplierCategory_list: 'supplierCategory_list',
            supplierCategory_view: 'supplierCategory_view',
            supplierCategory_create: 'supplierCategory_create',
            supplierCategory_edit: 'supplierCategory_edit',
            supplierCategory_delete: 'supplierCategory_delete',
        },
        user: {
            user_list: 'user_list',
            user_view: 'user_view',
            user_create: 'user_create',
            user_edit: 'user_edit',
            user_delete: 'user_delete',
            user_permissions: 'user_permissions',
        },
    },
    toast: { position: 'top-right', duration: 3000 },


};