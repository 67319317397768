//Media
import axios from "axios";
import {processHttpErrors} from "@/helpers/commonHelper";

export async function saveFile(id, file, endpoint, extraFields = {}) {
    let formData = new FormData();
    formData.append('id', id);
    formData.append('upload_file', file);

    for (const [key, value] of Object.entries(extraFields)) {
        if(value) formData.append(key, value);
    }

    return await axios.post(endpoint, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
        .then((response) => {
            if(response.data.status === 'success'){
                return true;
            }else{
                return false;
            }
        })
        .catch((error) => {
            processHttpErrors(error, true);
            return false;
        })
}