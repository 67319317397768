export default {
    namespaced: true,

    state: {
        sidebarOpen: false,
    },

    getters: {
        sidebarOpen (state) {
            return state.sidebarOpen
        },
    },

    mutations: {
        SET_SIDEBAR_OPEN (state, value) {
            state.sidebarOpen = value
        },
    },

    actions: {
        setSidebarOpen ({ commit }, {value}) {
            commit('SET_SIDEBAR_OPEN', value);
        },
    }
}