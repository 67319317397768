import Vue from 'vue'
import App from './App.vue'
//import 'admin-lte/plugins/fontawesome-free/css/all.min.css';
//import 'admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css';
import './assets/fontawesome-pro/css/all.min.css';
import 'admin-lte/dist/css/adminlte.min.css';
import './assets/css/main.css';

import 'admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js';
//import 'admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js';
import 'admin-lte/dist/js/adminlte.js';
import router from './router/index'
import store from './store'
import axios from 'axios'

Vue.prototype.$store = store;

import {static_settings} from "./static/settings";
Vue.prototype.$globalSettings = static_settings;

import {enums} from "./static/enums";
Vue.prototype.$globalEnums = enums;

/*import commonHelper from "./helpers/commonHelper";
Vue.prototype.$globalHelpers = commonHelper;*/

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading, static_settings.loading);

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import {UserService} from "@/services/user-service";
Vue.use(VueToast, static_settings.toast);

Vue.config.productionTip = false;
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
Vue.prototype.$apiBaseURL = axios.defaults.baseURL;

new UserService().userInit().then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
});