export default {
    namespaced: true,

    state: {
        defaultCashAccount: null,
        caOnes: [],
        caTwos: [],
        caThrees: [],
        caWithAccounts: [],
        countries: [],
        customerCategories: [],
        defaultCustomer: null,
        defaultSupplier: null,
        options: [],
        packages: [],
        paymentMethods: [],
        productBrands: [],
        productCats: [],
        productGroups: [],
        productUnits: [],
        shippingStatuses: [],
        stores: [],
        supplierCategories: [],
        users: [],
    },

    getters: {
        defaultCashAccount (state) {
            return state.defaultCashAccount
        },
        caOnes (state) {
            return state.caOnes
        },
        caTwos (state) {
            return state.caTwos
        },
        caThrees (state) {
            return state.caThrees
        },
        caWithAccounts (state) {
            return state.caWithAccounts
        },
        countries (state) {
            return state.countries
        },
        customerCategories (state) {
            return state.customerCategories
        },
        defaultCustomer (state) {
            return state.defaultCustomer
        },
        defaultSupplier (state) {
            return state.defaultSupplier
        },
        options (state) {
            return state.options
        },
        packages (state) {
            return state.packages
        },
        paymentMethods (state) {
            return state.paymentMethods
        },
        productBrands (state) {
            return state.productBrands
        },
        productCats (state) {
            return state.productCats
        },
        productGroups (state) {
            return state.productGroups
        },
        productUnits (state) {
            return state.productUnits
        },
        shippingStatuses (state) {
            return state.shippingStatuses
        },
        stores (state) {
            return state.stores
        },
        supplierCategories (state) {
            return state.supplierCategories
        },
        users (state) {
            return state.users
        },
    },

    mutations: {
        SET_DEFAULT_CASH_ACCOUNT (state, value) {
            state.defaultCashAccount = value
        },
        SET_CA_ONES (state, value) {
            state.caOnes = value
        },
        SET_CA_TWOS (state, value) {
            state.caTwos = value
        },
        SET_CA_THREES (state, value) {
            state.caThrees = value
        },
        SET_CA_WITH_ACCOUNTS (state, value) {
            state.caWithAccounts = value
        },
        SET_COUNTRIES (state, value) {
            state.countries = value
        },
        SET_CUSTOMER_CATEGORIES (state, value) {
            state.customerCategories = value
        },
        SET_DEFAULT_CUSTOMER (state, value) {
            state.defaultCustomer = value
        },
        SET_DEFAULT_SUPPLIER (state, value) {
            state.defaultSupplier = value
        },
        SET_OPTIONS (state, value) {
            state.options = value
        },
        SET_PACKAGES (state, value) {
            state.packages = value
        },
        SET_PAYMENT_METHODS (state, value) {
            state.paymentMethods = value
        },
        SET_PRODUCT_BRANDS (state, value) {
            state.productBrands = value
        },
        SET_PRODUCT_CATS (state, value) {
            state.productCats = value
        },
        SET_PRODUCT_GROUPS (state, value) {
            state.productGroups = value
        },
        SET_PRODUCT_UNITS (state, value) {
            state.productUnits = value
        },
        SET_SHIPPING_STATUSES (state, value) {
            state.shippingStatuses = value
        },
        SET_STORES (state, value) {
            state.stores = value
        },
        SET_SUPPLIER_CATEGORIES (state, value) {
            state.supplierCategories = value
        },
        SET_USERS (state, value) {
            state.users = value
        },
    },

    actions: {
        setDefaultCashAccount({ commit }, {value}) {
            commit('SET_DEFAULT_CASH_ACCOUNT', value);
        },
        setCaOnes({ commit }, {value}) {
            commit('SET_CA_ONES', value);
        },
        setCaTwos({ commit }, {value}) {
            commit('SET_CA_TWOS', value);
        },
        setCaThrees({ commit }, {value}) {
            commit('SET_CA_THREES', value);
        },
        caWithAccounts({ commit }, {value}) {
            commit('SET_CA_WITH_ACCOUNTS', value);
        },
        setCountries({ commit }, {value}) {
            commit('SET_COUNTRIES', value);
        },
        setCustomerCategories({ commit }, {value}) {
            commit('SET_CUSTOMER_CATEGORIES', value);
        },
        setDefaultCustomer({ commit }, {value}) {
            commit('SET_DEFAULT_CUSTOMER', value);
        },
        setDefaultSupplier({ commit }, {value}) {
            commit('SET_DEFAULT_SUPPLIER', value);
        },
        setOptions({ commit }, {value}) {
            commit('SET_OPTIONS', value);
        },
        setPackages({ commit }, {value}) {
            commit('SET_PACKAGES', value);
        },
        setPaymentMethods({ commit }, {value}) {
            commit('SET_PAYMENT_METHODS', value);
        },
        setProductBrands({ commit }, {value}) {
            commit('SET_PRODUCT_BRANDS', value);
        },
        setProductCats({ commit }, {value}) {
            commit('SET_PRODUCT_CATS', value);
        },
        setProductGroups({ commit }, {value}) {
            commit('SET_PRODUCT_GROUPS', value);
        },
        setProductUnits({ commit }, {value}) {
            commit('SET_PRODUCT_UNITS', value);
        },
        setShippingStatuses({ commit }, {value}) {
            commit('SET_SHIPPING_STATUSES', value);
        },
        setStores({ commit }, {value}) {
            commit('SET_STORES', value);
        },
        setSupplierCategories({ commit }, {value}) {
            commit('SET_SUPPLIER_CATEGORIES', value);
        },
        setUsers({ commit }, {value}) {
            commit('SET_USERS', value);
        },
    }
}