import axios from "axios";
import Vue from "vue";

import {processHttpErrors} from "@/helpers/commonHelper";
import {saveFile} from "@/helpers/mediaHelper";
export class UserService {
    formDataUser = {};
    formDataUserPermissions = {};
    formDataForgot = {};

    constructor() {
        this.formDataUser = {};
        this.formDataUserPermissions = {};
        this.formDataForgot = {};
    }

    async login(){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.authentication.loginProcess, { email: this.formDataUser.email, password: this.formDataUser.password })
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, status: response.data.status};
            })
            .catch((error) => {
                processHttpErrors(error, false);
                return {isSuccess: false, message: 'Something went wrong!', status: 'error'};
            })
    }

    async forgotOTP(){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.authentication.forgotOTP, { email: this.formDataForgot.email })
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, status: response.data.status};
            })
            .catch((error) => {
                processHttpErrors(error, false);
                return {isSuccess: false, message: 'Something went wrong!', status: 'error'};
            })
    }

    async forgotReset(){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.authentication.forgotReset, this.formDataForgot)
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, status: response.data.status};
            })
            .catch((error) => {
                processHttpErrors(error, false);
                return {isSuccess: false, message: 'Something went wrong!', status: 'error'};
            })
    }

    async userInit() {
        return await axios.get(Vue.prototype.$globalSettings.api.endpoints.user.userInit)
            .then((response) => {
                if(response.data.status === 'success'){
                    Vue.prototype.$store.dispatch('auth/userInit', {authenticated: true, user: response.data.user});
                    return {isSuccess: true, user: response.data.user, status: response.data.status};
                }else{
                    Vue.prototype.$store.dispatch('auth/userInit', {authenticated: false, user: null});
                    return {isSuccess: false, user: null, status: response.data.status};
                }
            })
            .catch((error) => {
                processHttpErrors(error, false);
                Vue.prototype.$store.dispatch('auth/userInit', {authenticated: false, user: null});
                return {isSuccess: false, user: null, status: 'error'};
            })
    }

    async signup(){
        if(this.formDataUser.password !== this.formDataUser.password_confirmation){
            return { isSuccess: false, message: 'Please type same password in the Retype Password box!'};
        }
        if(!this.formDataUser.terms_agree){
            return { isSuccess: false, message: 'You must agree to the terms and privacy policy!'};
        }
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.authentication.signupProcess, {name: this.formDataUser.name, email: this.formDataUser.email, password: this.formDataUser.password, password_confirmation: this.formDataUser.password_confirmation, terms_agree: this.formDataUser.terms_agree}).then(async (response) => {

            return { isSuccess: response.data.status === 'success', message: response.data.msg, status: response.data.status};
        }).catch((error) => {
            processHttpErrors(error, true);
            return { isSuccess: false, message: 'Something went wrong!', status: 'error'};
        });
    }

    async logout() {
        return await axios.get(Vue.prototype.$globalSettings.api.endpoints.authentication.logout)
            .then((response) => {
                return response.data.status === 'success';
            })
            .catch((error) => {
                processHttpErrors(error, false);
                return false;
            })
    }

    async getUsers(fetch){
        let users = Vue.prototype.$store.getters['common/users'];
        if(users.length <= 0 || fetch){
            return await axios.post(Vue.prototype.$globalSettings.api.endpoints.user.users, {
                sortBy: 'name',
                sort: 'asc'
            })
                .then((response) => {
                    if(response.data.status === 'success'){
                        users = response.data.users;
                        Vue.prototype.$store.dispatch('common/setUsers', {value: users});
                    }
                    return {isSuccess: response.data.status === 'success', message: response.data.msg, users: users};
                })
                .catch((error) => {
                    processHttpErrors(error, true);
                    return {isSuccess: false, message: 'Something went wrong!', users: []};
                });
        }
        return {isSuccess: true, message: '', users: users};
    }

    async getUser(id, mode){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.user.user, {
            id: id,
            mode: mode,
        })
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, user: response.data.user};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', user: null};
            });
    }
    async saveUser(upload_image){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.user.userSave, this.formDataUser).then(async (response) => {
            if(upload_image) {
                if (!await saveFile(response.data.id, upload_image, Vue.prototype.$globalSettings.api.endpoints.user.userSaveFile)) {
                    return {isSuccess: false, message: 'Image upload failed!', user: null};
                }
            }
            await this.getUsers(true);
            return {isSuccess: response.data.status === 'success', message: response.data.msg, user: response.data.user};
        }).catch((error) => {
            processHttpErrors(error, true);
            return {isSuccess: false, message: 'Something went wrong!', user: null};
        });
    }

    async delete(id){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.user.userDelete, {
            id: id
        })
            .then(async (response) => {
                await this.getUsers(true);
                return {isSuccess: response.data.status === 'success', message: response.data.msg};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!'};
            })
    }

    async getUserPermissions(id){
        return await axios.get(Vue.prototype.$globalSettings.api.endpoints.user.userPermissions + id)
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, user: response.data.user, package_permissions: response.data.package_permissions};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', user: null, package_permissions: null};
            })
    }

    async saveUserPermissions(){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.user.userPermissionsSave, this.formDataUserPermissions).then(async (response) => {
            return {isSuccess: response.data.status === 'success', message: response.data.msg, save_error_permissions: response.data.save_error_permissions};
        }).catch((error) => {
            processHttpErrors(error, true);
            return {isSuccess: false, message: 'Something went wrong!', save_error_permissions: []};
        });
    }

    async checkPackageKeyPermission(filters){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.package.packageKeyPermission, filters)
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!'};
            })
    }
    async getPackages(){
        let packages = Vue.prototype.$store.getters['common/packages'];
        if(packages.length <= 0){
            return await axios.post(Vue.prototype.$globalSettings.api.endpoints.package.packages)
                .then((response) => {
                    if(response.data.status === 'success'){
                        packages = response.data.packages;
                        Vue.prototype.$store.dispatch('common/setPackages', {value: packages});
                    }
                    return {isSuccess: response.data.status === 'success', message: response.data.msg, packages: packages};
                })
                .catch((error) => {
                    processHttpErrors(error, true);
                    return {isSuccess: false, message: 'Something went wrong!', packages: []};
                })
        }
        return {isSuccess: true, message: '', packages: packages};
    }
}