import Vue from 'vue'
//import Vue.prototype.$store from '../Vue.prototype.$store/index';
//import axios from "axios";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

//UI
export function setSidebarOpen(value) {
    Vue.prototype.$store.dispatch('ui/setSidebarOpen', {value: value})
}
//form
export function getFormTypeName(id) {
    return Object.keys(Vue.prototype.$globalEnums.formTypes).find(key => Vue.prototype.$globalEnums.formTypes[key] === id);
}
export function getFormCategoryName(id) {
    return Object.keys(Vue.prototype.$globalEnums.formCategories).find(key => Vue.prototype.$globalEnums.formCategories[key] === id);
}

//opening balance
export function getOBEntityTypeName(id) {
    return Object.keys(Vue.prototype.$globalEnums.obEntityTypes).find(key => Vue.prototype.$globalEnums.obEntityTypes[key] === id);
}

//enum
export function enumToArray(enumObject){
    let output = [];
    for(let key in enumObject){
        output.push({ id: enumObject[key], label: key });
    }
    return output;
}

export function getEnumLabel(enumObject, value) {
    return Object.keys(enumObject).find(key => enumObject[key] === value);
}

//Object
export function kvObjectToKvArray(obj){
    let output = [];
    for (let prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            output.push({key: prop, val: obj[prop]});
        }
    }
    return output;
}

//error handling
export function processHttpErrors(error, showToast) {
    if(error.response.status === 422){
        for(let err in error.response.data.errors){
            if (Object.prototype.hasOwnProperty.call(error.response.data.errors, err)) {
                if(showToast) {
                    Vue.$toast.open({message: error.response.data.errors[err][0], type: 'error'});
                }else console.log(error.response.data.errors[err][0]);
            }
        }
    }else{
        if(error !== undefined || error !== null) {
            if (showToast) {
                Vue.$toast.open({message: error, type: 'error'});
            } else {
                console.log(error);
            }
        }
    }
}