<template>
    <div>
        <NavBar v-if="$route.name !== 'App.Sale.POS'"></NavBar>
        <SideBar v-if="$route.name !== 'App.Sale.POS'"></SideBar>
        <router-view></router-view>
    </div>
</template>

<script>
    import NavBar from "../../components/app/common/NavBar";
    import SideBar from "../../components/app/common/SideBar";

    export default {
        name: "Layout",
        components: {
            NavBar,
            SideBar
        }
    }
</script>

<style scoped>

</style>