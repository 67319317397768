<template>
    <!-- Main Sidebar Container -->
    <aside class="main-sidebar sidebar-light-primary elevation-0 sidebar-custom">
        <!-- Brand Logo -->
        <router-link :to="{ name: 'App.Dashboard'}" class="brand-link">
<!--            <img :src="$globalSettings.app_icon" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8">-->
            <span class="font-size-25 ml-3"><img src="images/logo-acc-transparent-50w.png" /> PoS</span>
        </router-link>

        <!-- Sidebar -->
        <div class="sidebar pt-3">
            <!-- Sidebar user panel (optional) -->
<!--            <div class="user-panel mt-3 pb-3 pt-3 mb-3 d-flex">
                <div class="image">
                    <img src="/images/user.png" class="img-circle elevation-2" alt="User Image">
                </div>
                <div class="info">
                    {{ user.name }}
                </div>
            </div>-->

            <!-- SidebarSearch Form -->
            <!--<div class="form-inline">
            <div class="input-group" data-widget="sidebar-search">
                <input class="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search">
                <div class="input-group-append">
                    <button class="btn btn-sidebar">
                        <i class="fas fa-search fa-fw"></i>
                    </button>
                </div>
            </div>
        </div>-->

            <!-- Sidebar Menu -->
            <nav class="">
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    <li class="nav-item" v-for="module in modules" :key="module.routeName">
                        <router-link :to="{ name: module.routeName, params: {type: module.routeParams.type}}" class="nav-link" :class="$route.name === module.routeName? 'active': ''">
                            <i :class="module.iconClass" class="small-fa-icons"></i>
                            <p> {{module.label}}</p>
                        </router-link>
                    </li>
                </ul>
                <div class="footer-version">v1.0.7</div>
            </nav>
            <!-- /.sidebar-menu -->
<!--            <div>Copyright &copy; 2022, OVOPoS. All rights Reserved</div>-->

        </div>
    </aside>
</template>

<script>
import {isAdmin, getPermissionByKey} from "@/helpers/userHelper";

    export default {
        name: 'NavBar',
        data: function(){
            return {
                user: {},
                modules: [
                    { routeName: 'App.Dashboard', routeParams: {}, label: 'Dashboard', iconClass: 'fas fa-tachometer-alt', permissionKeys: [], isDefault: true, adminOnly: false},
                    { routeName: 'App.Sales', routeParams: {}, label: 'Sales', iconClass: 'fas fa-cash-register', permissionKeys: [this.$globalSettings.permissions.sale.sale_order_list, this.$globalSettings.permissions.sale.sale_invoice_list, this.$globalSettings.permissions.sale.sale_return_list], isDefault: false, adminOnly: false},
                    { routeName: 'App.Customers', routeParams: {}, label: 'Customers', iconClass: 'far fa-person-carry', permissionKeys: [this.$globalSettings.permissions.customer.customer_list], isDefault: false, adminOnly: false},
                    { routeName: 'App.Products', routeParams: {}, label: 'Products', iconClass: 'fas fa-cubes', permissionKeys: [this.$globalSettings.permissions.product.product_list], isDefault: false, adminOnly: false},
                    { routeName: 'App.Accounts', routeParams: {}, label: 'Account Heads', iconClass: 'fas fa-book', permissionKeys: [this.$globalSettings.permissions.account.account_list], isDefault: false, adminOnly: false},
                    { routeName: 'App.Payments', routeParams: {}, label: 'Payments', iconClass: 'far fa-credit-card', permissionKeys: [this.$globalSettings.permissions.payment.payment_list], isDefault: false, adminOnly: false},
                    { routeName: 'App.Purchases', routeParams: {}, label: 'Purchases', iconClass: 'far fa-dolly-flatbed-alt', permissionKeys: [this.$globalSettings.permissions.purchase.purchase_invoice_list, this.$globalSettings.permissions.purchase.purchase_return_list], isDefault: false, adminOnly: false},
                    { routeName: 'App.Suppliers', routeParams: {}, label: 'Suppliers', iconClass: 'far fa-person-dolly', permissionKeys: [this.$globalSettings.permissions.supplier.supplier_list], isDefault: false, adminOnly: false},
                    { routeName: 'App.Stores', routeParams: {}, label: 'Stores', iconClass: 'fas fa-store', permissionKeys: [], isDefault: false, adminOnly: true},
                    { routeName: 'App.Users', routeParams: {}, label: 'Users', iconClass: 'fas fa-users', permissionKeys: [this.$globalSettings.permissions.user.user_list], isDefault: false, adminOnly: false},
                    { routeName: 'App.Reports', routeParams: {}, label: 'Reports', iconClass: 'fas fa-file-chart-line', permissionKeys: [this.$globalSettings.permissions.user.user_list], isDefault: false, adminOnly: false},
                    { routeName: 'App.Settings', routeParams: {}, label: 'Settings', iconClass: 'fas fa-sliders-h', permissionKeys: [this.$globalSettings.permissions.user.user_list], isDefault: false, adminOnly: false},
                    { routeName: 'App.Logs', routeParams: {}, label: 'Logs', iconClass: 'fas fa-history', permissionKeys: [], isDefault: false, adminOnly: true},
                    //{ routeName: 'App.Billing', routeParams: {}, label: 'Billing', iconClass: 'far fa-credit-card', permissionKeys: [], isDefault: false, adminOnly: true},
                    /*{ routeName: 'App.Packages', routeParams: {}, label: 'Packages', iconClass: 'fas fa-award', permissionKeys: [], isDefault: false, adminOnly: true},*/
                ]
            }
        },
        props: {},
        methods: {

        },
        created() {
            let mods = [];
            this.modules.forEach((module) => {
                if(module.isDefault) {
                    mods.push(module);
                }else if(module.adminOnly) {
                    if(isAdmin()){
                        mods.push(module);
                    }
                }else{
                    for(let i = 0; i < module.permissionKeys.length; i++){
                        if(getPermissionByKey(module.permissionKeys[i])){
                            mods.push(module);
                            break;
                        }
                    }
                }

                //future code
                /*if(!module.isDefault) {
                    if(getPermissionByKey(module.permissionKey)){
                        let children = [];
                        module.children.forEach((child) => {
                            if(!child.isDefault) {
                                if(getPermissionByKey(child.permissionKey)){
                                    children.push(child);
                                }
                            }else{
                                children.push(child);
                            }
                        });
                        module.children = children;
                        mods.push(module);
                    }
                }else{
                    mods.push(module);
                }*/
            });
            this.modules = mods;
        }
    }
</script>

<style scoped>

</style>
