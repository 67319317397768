export const enums = {
    roles:{
        'SuperAdmin': 1,
        'Admin': 2,
        'User': 3,
    },
    paymentTypes: {
        'Receive': 1,
        'Pay': 2,
        'Reconciliation': 3,
        'JournalEntry': 4,
        'Sale': 5,
        'Purchase': 6,
        'SalesReturn': 7,
        'PurchaseReturn': 8,
    },
    formTypes: {
        'Sale': 1,
        'Purchase': 2,
    },
    formCategories: {
        'Quote': 1,
        'Order': 2,
        'Invoice': 3,
        'Delivery': 4,
        'Return': 5
    },
    obEntityTypes: {
        'Account': 1,
        'Product': 2,
        'Customer': 3,
        'Supplier': 4
    },
    billingPaymentMethods:{
        'Manual': 1,
        'Bank': 2,
        'Card': 3,
        'Cheque': 4,
        'Wallet Balance': 5,
        'MFS': 6,
        'PayPal': 7,
        'Wire': 8,
        'Paddle': 9,
        'Cash': 10,
    },
};