<template>
    <!-- Navbar -->
    <nav class="main-header navbar navbar-expand navbar-custom">
        <!-- Left navbar links -->
        <ul class="navbar-nav">
            <li class="nav-item">
                <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
            </li>
            <li class="nav-item d-none d-sm-inline-block">
                <router-link :to="{ name: 'App.Sale.POS'}" class="nav-link" :class="$route.name === 'App.Sale.POS'? 'active': ''"><i class="fas fa-cash-register"></i> POS</router-link>
            </li>
        </ul>

        <!-- Right navbar links -->
        <ul class="navbar-nav ml-auto">
            <!-- Navbar Search -->
            <!--<li class="nav-item">
            <a class="nav-link" data-widget="navbar-search" href="#" role="button">
              <i class="fas fa-search"></i>
            </a>
            <div class="navbar-search-block">
              <form class="form-inline">
                <div class="input-group input-group-sm">
                  <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search">
                  <div class="input-group-append">
                    <button class="btn btn-navbar" type="submit">
                      <i class="fas fa-search"></i>
                    </button>
                    <button class="btn btn-navbar" type="button" data-widget="navbar-search">
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </li>-->

            <!-- Messages Dropdown Menu -->
            <!--<li class="nav-item dropdown">
            <a class="nav-link" data-toggle="dropdown" href="#">
              <i class="far fa-comments"></i>
              <span class="badge badge-danger navbar-badge">3</span>
            </a>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <a href="#" class="dropdown-item">
                &lt;!&ndash; Message Start &ndash;&gt;
                <div class="media">
                  <img src="dist/img/user1-128x128.jpg" alt="User Avatar" class="img-size-50 mr-3 img-circle">
                  <div class="media-body">
                    <h3 class="dropdown-item-title">
                      Brad Diesel
                      <span class="float-right text-sm text-danger"><i class="fas fa-star"></i></span>
                    </h3>
                    <p class="text-sm">Call me whenever you can...</p>
                    <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
                  </div>
                </div>
                &lt;!&ndash; Message End &ndash;&gt;
              </a>
              <div class="dropdown-divider"></div>
              <a href="#" class="dropdown-item">
                &lt;!&ndash; Message Start &ndash;&gt;
                <div class="media">
                  <img src="dist/img/user8-128x128.jpg" alt="User Avatar" class="img-size-50 img-circle mr-3">
                  <div class="media-body">
                    <h3 class="dropdown-item-title">
                      John Pierce
                      <span class="float-right text-sm text-muted"><i class="fas fa-star"></i></span>
                    </h3>
                    <p class="text-sm">I got your message bro</p>
                    <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
                  </div>
                </div>
                &lt;!&ndash; Message End &ndash;&gt;
              </a>
              <div class="dropdown-divider"></div>
              <a href="#" class="dropdown-item">
                &lt;!&ndash; Message Start &ndash;&gt;
                <div class="media">
                  <img src="dist/img/user3-128x128.jpg" alt="User Avatar" class="img-size-50 img-circle mr-3">
                  <div class="media-body">
                    <h3 class="dropdown-item-title">
                      Nora Silvester
                      <span class="float-right text-sm text-warning"><i class="fas fa-star"></i></span>
                    </h3>
                    <p class="text-sm">The subject goes here</p>
                    <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
                  </div>
                </div>
                &lt;!&ndash; Message End &ndash;&gt;
              </a>
              <div class="dropdown-divider"></div>
              <a href="#" class="dropdown-item dropdown-footer">See All Messages</a>
            </div>
          </li>-->
            <!-- Notifications Dropdown Menu -->
            <li class="nav-item dropdown">
                <a class="nav-link link-black" data-toggle="dropdown" href="#">
                    <img :src="user.image? $apiBaseURL + '/' + user.image: '/images/user.png'" class="img-circle" height="18">{{ user.name }}
                </a>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <router-link :to="{ name: 'App.User.My'}" class="dropdown-item">
                        <i class="fas fa-user-cog small-fa-icons"></i> My Account
                    </router-link>
                    <div class="dropdown-divider" v-if="user.role_id === 2"></div>
                    <router-link :to="{ name: 'App.Company.Details'}" class="dropdown-item" v-if="user.role_id === 2">
                        <i class="far fa-building small-fa-icons"></i> Company Details
                    </router-link>
                    <router-link :to="{ name: 'App.Packages'}" class="dropdown-item" v-if="user.role_id === 2">
                        <i class="fas fa-award small-fa-icons"></i> Packages
                    </router-link>
<!--                    <router-link :to="{ name: 'App.Packages'}" class="dropdown-item" v-if="user.role_id === 2">
                        <i class="far fa-credit-card small-fa-icons"></i> Payments
                    </router-link>-->
                    <div class="dropdown-divider" v-if="user.role_id === 2"></div>
                    <a @click.prevent="logout" class="dropdown-item" href="#">
                        <i class="fas fa-sign-out-alt small-fa-icons"></i> Logout
                    </a>
                </div>
            </li>

            <li class="nav-item">
                <a class="nav-link" data-widget="fullscreen" href="#" role="button">
                    <i class="fas fa-expand-arrows-alt"></i>
                </a>
            </li>
            <!--<li class="nav-item">
                <a class="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button">
                    <i class="fas fa-th-large"></i>
                </a>
            </li>-->
        </ul>
    </nav>
    <!-- /.navbar -->
</template>

<script>
    import {UserService} from "@/services/user-service";

    export default {
        name: 'NavBar',
        data: function(){
            return {}
        },
        props: {},
        computed:{
            user: function(){
                return this.$store.getters['auth/user'];
            },
        },
        methods: {
            async logout() {
                let loader = this.$loading.show();

                let authService = new UserService();
                if(await authService.logout()){
                    await this.$router.push({name: 'Login'});
                    loader.hide();
                }else{
                    this.$toast.open({ message: 'Logout failed! Please try again.',  type: 'error'});
                }
            }
        },
        async created() {

        }
    }
</script>

<style scoped>

</style>
