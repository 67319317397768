import Vue from "vue";
import {enums} from "@/static/enums";

export function getOwnUserPermissions() {
    let user = Vue.prototype.$store.getters['auth/user'];
    if (user.permissions) {
        return JSON.parse(user.permissions);
    }else{
        return {};
    }
}

export function getRoleName(id) {
    return Object.keys(Vue.prototype.$globalEnums.roles).find(key => Vue.prototype.$globalEnums.roles[key] === id);
}

export function isAdmin() {
    let user = Vue.prototype.$store.getters['auth/user'];
    return user.role_id === enums.roles.Admin;
}

export function getOwnUserEmail() {
    let user = Vue.prototype.$store.getters['auth/user'];
    return user.email
}
export function getOwnUserID() {
    let user = Vue.prototype.$store.getters['auth/user'];
    return user.id
}

export function getPermissionByKey(key) {
    let user = Vue.prototype.$store.getters['auth/user'];
    if(user.role_id) {
        if (user.role_id === enums.roles.Admin) {
            return true;
        } else {
            if (user.permissions) {
                let permissions = JSON.parse(user.permissions);
                return permissions.some(x => x === key);
            } else {
                return false;
            }
        }
    }else{
        return false;
    }
}