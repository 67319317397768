import Vue from 'vue'
import Vuerouter from 'vue-router'
import Layout from "../views/app/Layout_view";

Vue.use(Vuerouter);

const routes = [
    {
        path: '/',
        name: 'Login',
        meta: {isPrivate: false},
        component: () => import('../views/Auth/Login_view')
    },
    {
        path: '/auth/signup',
        name: 'Signup',
        meta: {isPrivate: false},
        component: () => import('../views/Auth/Signup_view')
    },
    {
        path: '/auth/forgot/:email?/:otp?',
        name: 'Forgot',
        meta: {isPrivate: false},
        component: () => import('../views/Auth/Forgot_view')
    },
    {
        path: '/app',
        name: 'App',
        component: Layout,
        meta: {isPrivate: true},
        children: [
            {
                path: 'billing',
                name: 'App.Billing',
                meta: {isPrivate: true},
                component: () => import('../views/app/billing/Billing_view')
            },
            {
                path: 'company-details',
                name: 'App.Company.Details',
                meta: {isPrivate: true},
                component: () => import('../views/app/Company_view')
            },
            {
                path: 'customers',
                name: 'App.Customers',
                meta: {isPrivate: true},
                component: () => import('../views/app/customer/Customers_view')
            },
            {
                path: 'customer',
                name: 'App.Customer',
                meta: {isPrivate: true},
                component:  () => import('../views/app/customer/Customer_view')
            },
            {
                path: 'dashboard',
                name: 'App.Dashboard',
                meta: {isPrivate: true},
                component: () => import('../views/app/Dashboard_view')
            },
            {
                path: 'chart-of-accounts',
                name: 'App.ChartOfAccounts',
                meta: {isPrivate: true},
                component: () => import('../views/app/account/ChartOfAccounts_view')
            },
            {
                path: 'accounts',
                name: 'App.Accounts',
                meta: {isPrivate: true},
                component: () => import('../views/app/account/Accounts_view')
            },
            {
                path: 'logs',
                name: 'App.Logs',
                meta: {isPrivate: true},
                component: () => import('../views/app/log/Logs_view')
            },
            {
                path: 'payments',
                name: 'App.Payments',
                meta: {isPrivate: true},
                component: () => import('../views/app/payment/Payments_view')
            },
            {
                path: 'payments-invoice',
                name: 'App.Payments.Invoice',
                meta: {isPrivate: true},
                component: () => import('../views/app/payment/PaymentsInvoice_view')
            },
            {
                path: 'payment',
                name: 'App.Payment',
                meta: {isPrivate: true},
                component: () => import('../views/app/payment/Payment_view')
            },
            {
                path: 'products',
                name: 'App.Products',
                meta: {isPrivate: true},
                component: () => import('../views/app/product/Products_view')
            },
            {
                path: 'product',
                name: 'App.Product',
                meta: {isPrivate: true},
                component: () => import('../views/app/product/Product_view')
            },
            {
                path: 'product-labels',
                name: 'App.Product.Labels',
                meta: {isPrivate: true},
                component: () => import('../views/app/product/ProductLabels_view')
            },
            {
                path: 'purchases',
                name: 'App.Purchases',
                meta: {isPrivate: true},
                component: () => import("../views/app/purchase/Purchases_view")
            },
            {
                path: 'purchase',
                name: 'App.Purchase',
                meta: {isPrivate: true},
                component: ()=> import('../views/app/purchase/Purchase_view')
            },
            {
                path: 'reports',
                name: 'App.Reports',
                meta: {isPrivate: true},
                component: () => import("../views/app/report/Reports_view")
            },
            {
                path: 'sales',
                name: 'App.Sales',
                meta: {isPrivate: true},
                component: () => import("../views/app/sale/Sales_view")
            },
            {
                path: 'sale',
                name: 'App.Sale',
                meta: {isPrivate: true},
                component: ()=> import('../views/app/sale/Sale_view')
            },
            {
                path: 'sale-pos',
                name: 'App.Sale.POS',
                meta: {isPrivate: true},
                component: ()=> import('../views/app/sale/SalePOS_view')
            },
            {
                path: 'settings',
                name: 'App.Settings',
                meta: {isPrivate: true},
                component: () => import('../views/app/Settings_view')
            },
            {
                path: 'stores',
                name: 'App.Stores',
                meta: {isPrivate: true},
                component: () => import('../views/app/store/Stores_view')
            },
            {
                path: 'store',
                name: 'App.Store',
                meta: {isPrivate: true},
                component: () => import('../views/app/store/Store_view')
            },
            {
                path: 'supplier-categories',
                name: 'App.Supplier.Categories',
                meta: {isPrivate: true},
                component: () => import('../components/app/supplier/SupplierCategories')
            },
            {
                path: 'supplier-category',
                name: 'App.Supplier.Category',
                meta: {isPrivate: true},
                component: () => import('../components/app/supplier/SupplierCategory')
            },
            {
                path: 'suppliers',
                name: 'App.Suppliers',
                meta: {isPrivate: true},
                component: () => import('../views/app/supplier/Suppliers_view')
            },
            {
                path: 'supplier',
                name: 'App.Supplier',
                meta: {isPrivate: true},
                component: () => import('../views/app/supplier/Supplier_view')
            },
            {
                path: 'users',
                name: 'App.Users',
                meta: {isPrivate: true},
                component: () => import('../views/app/user/Users_view')
            },
            {
                path: 'user',
                name: 'App.User',
                meta: {isPrivate: true},
                component: () => import('../views/app/user/User_view')
            },
            {
                path: 'my-account',
                name: 'App.User.My',
                meta: {isPrivate: true},
                component: () => import('../views/app/user/MyAccount_view')
            },
            {
                path: 'user-permissions/:id',
                name: 'App.User.Permissions',
                meta: {isPrivate: true},
                component: () => import('../views/app/user/UserPermissions_view'),
            },
            {
                path: 'packages',
                name: 'App.Packages',
                meta: {isPrivate: true},
                component: () => import('../views/app/package/Packages_view')
            },
        ]
    },
    /*{
      path: '/about',
      name: 'About',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/!* webpackChunkName: "about" *!/ '../views/About.vue')
    }*/
];

const router = new Vuerouter({
    routes
});

router.beforeEach((to, from, next) => {
    const isPrivate = to.meta.isPrivate;
    let store = Vue.prototype.$store;
    if (isPrivate) {
        if (store.getters["auth/authenticated"]) {
            let user = store.getters["auth/user"];
            if (user) {
                if (user.company) {
                    return next();
                } else {
                    if (to.name === 'App.Company.Details') {
                        return next();
                    } else {
                        return next({name: 'App.Company.Details'});
                    }
                }
            } else {
                return next({name: 'Login'});
            }
        } else {
            return next({name: 'Login'});
        }
    } else {
        return next();
    }
});

export default router
