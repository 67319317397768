import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import ui from './ui';
import common from "@/store/common";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth, ui, common
  }
})
